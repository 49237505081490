@import "./variables";

$formWidth: 400px;

.login-page, .signup-page, .password-page {
  width: 100vw;
  height: 100vh;
  background: $background-gradient;
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    width: $formWidth;
    height: 100px;
  }

  .card {
    width: calc(#{$formWidth} + 100px);
    border-radius: 3px;
    text-align: center;
  }

  .form {
    width: $formWidth;
    padding: 30px 40px 40px;
    margin: auto;
  }

  .actions-container {
    width: $formWidth;
    padding: 10px 40px;
    margin-top: 40px;
    text-align: center;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-top: 20px;
  }

  .consent {
    color: #ddd;
  }

  .link {
    color: #eee;
    text-decoration: none;
    cursor: pointer;
  }
}