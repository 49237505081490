#invoice {
  position: relative;

  input, textarea {
    outline: 0;
  }

  .fakeInput, input:not([readonly]), .MuiInputBase-root {
    font-size: 14px;
    border: 1px dashed #bfcee1;
    transition: all .4s;
    transition-property: border-color,color,opacity;
    line-height: 20px;
    padding: 5px 9px;
    overflow: hidden;
    color: inherit;
    resize: none;
    cursor: pointer;
  }

  .twitter-picker input {
    padding: 0;
  }

  .MuiFormControl-root, .MuiInputBase-root {
    width: 100%;
    padding: 0;
    text-align: right;
  }

  .MuiNativeSelect-select {
    padding: 5px 9px 8px 35px;
  }

  .MuiNativeSelect-select:focus {
    background-color: transparent;
  }

  .colorPicker {
    transition: all .4s;
  }

  div[placeholder]:empty:before {
    content: attr(placeholder);
    display: block;
    color: #666;
  }

  &:not(:hover) {
    .fakeInput, .MuiInputBase-root {
      border: 1px solid #fff;

      &:placeholder-shown {
        visibility: hidden;
      }
    }

    button {
      visibility: hidden;
    }

    .ButtonAddLine {
      opacity: 0;
    }

    .colorPicker {
      opacity: 0;
    }

    div[placeholder]:empty:before {
      opacity: 0;
    }
  }

  .invoiceEditorHeader {
    display: flex;
    flex-direction: row;
    max-height: 366px;
    min-height: 366px;
    justify-content: space-between;

    .invoiceEditorEmitter {
      display: flex;
      flex-direction: column;
      width: 350px;
      max-width: 100%;
      padding-bottom: 10px;
      white-space: pre;

      .logoWrapper {
        display: flex;
        width: 350px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        box-sizing: border-box;
        cursor: initial;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          max-width: 180px;
          max-height: 100px;
        }
      }

      .emitterInfos {
        font-size: 14px;
        // border: 1px dashed #bfcee1;
        transition: all .4s;
        transition-property: border-color,color;
        padding: 5px 9px;
        line-height: 20px;
        overflow: hidden;
        color: inherit;
        resize: none;
        cursor: pointer;
        padding: 5px 9px;
        outline: 0;
      }
    }

    .invoiceEditorCustomer {
      margin-top: 124px;

      .invoiceEditorCustomerWrapper {
        display: flex;
        flex-direction: column;
        width: 340px;
        position: relative;

        &.selectedClient > span {
          display: block;
          padding: 5px 9px;
          line-height: 20px;
          border-top: 1px solid #fff;
        }
      }
    }
  }

  .invoiceEditorBody {
    flex-direction: column;
    display: flex;
    flex: 1 1 0px;
    margin-bottom: 20px;

    .invoiceType {
      line-height: 20px;
      margin: 10px 0 10px 10px;
    }

    .invoiceTitle {
      flex: 1;
      line-height: 20px;
      font-weight: 700;
      margin: 0 0 10px;
    }

    .invoiceDates {
      display: flex;
      flex-direction: row;
      line-height: 20px;
      margin: 0 0 10px;

      span:not(:last-child) {
        margin-right: 5px;
      }

      .fakeInput {
        flex: 1;
      }

      input {
        color: inherit;
        font-size: 14px;
        padding: 0;
        border: none;
        cursor: pointer;
      }
    }

    .mention {
      flex: 1;
      line-height: 20px;
      margin: 0 0 10px;

      &.tva {
        margin: 30px 0;
        text-align: right;
      }
    }
  }

  .invoiceLines {
    position: relative;
  }

  .invoiceTotals {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 45%;
      align-self: flex-end;
      position: relative;
      font-size: 1.1rem;

      .total {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }

  .invoiceFooter {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    text-align: center;

    textarea {
      width: 100%;
      text-align: center;
    }
  }
}
