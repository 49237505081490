.header-logo {
  height: 50px;
}

.divider {
  background-color: #404854 !important;
}

.company-select {
  &:before {
    display: none !important;
    border-bottom: 1px solid white !important;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  }

  svg {
    color: #ffffff !important;
  }
}